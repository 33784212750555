import { mapGetters } from 'vuex'

export default {
  name: 'installment',
  components: {
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      activeIndex: 0,
      isExpand: true,
      installmentContents: [
      ],
      selectedTab: '',
      selectedContent: {
        contents: []
      }
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  watch: {
    activeLanguange() {
      this.fillFirstContent()
    }
  },
  created() {
    this.fillFirstContent()
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    imageName() {
      return this.selectedTab.replace(/\s/g, '-').toLowerCase()
    },
    activeLanguange() {
      return this.$i18n.locale
    },
    appProcessItems() {
      return this.$i18n.messages[this.activeLanguange].installment.faq.content
    }
  },
  methods: {
    fillFirstContent() {
      this.installmentContents = JSON.parse(JSON.stringify(this.appProcessItems))
      this.selectedTab = this.appProcessItems[this.activeIndex].tabName
      this.selectedContent.contents = this.appProcessItems[this.activeIndex].submenu.contents
    },
    selectTab(content, index) {
      this.activeIndex = index
      this.selectedTab = content.tabName
      this.selectedContent.contents = content.submenu.contents
    },
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    },
    toggleAccordion(itemIndex) {
      this.selectedContent.contents[itemIndex].status = !this.selectedContent.contents[itemIndex].status
    }
  }
}
