<template>
  <div class="installment">
    <div class="hero bg-grey w-full relative mdmax:px-4 overflow-hidden">
      <div class="shape-3 inline-block absolute top-0 z-0 -left-16 mdmax:hidden">
        <img src="@/assets/images/shape3.png" />
      </div>
      <div class="shape-1 inline-block absolute top-0 z-0 -right-14 mdmax:hidden">
        <img src="@/assets/images/shape1.png" />
      </div>
      <div class="shape-2 inline-block absolute bottom-10 left-32 scale-75 z-0 mdmax:hidden">
        <img src="@/assets/images/shape2.png" />
      </div>
      <div class="container mx-auto z-10">
        <div class="flex justify-between mdmax:flex-col">
          <div class="flex flex-col gap-2 w-1/2 h-full mdmax:w-full my-24 mdmax:my-0 mdmax:pt-10 mdmax:ml-0 pr-8">
            <div class="font-extrabold mdmax:font-bold leading-extra-loose text-5xl mdmax:text-2xl mdmax:mb-2 mdmax:w-full text-neutral flex flex-col z-10">
              <span
                >{{ $t('installment.hero.firstText') }} <span class="text-yellow">{{ $t('installment.hero.secondText') }}</span> {{ $t('installment.hero.thirdText') }}</span
              >
            </div>
            <div class="text-2xl mdmax:text-base text-neutral w-full leading-relaxed">
              {{ $t('installment.hero.desc') }}
            </div>
          </div>
          <div class="w-1/2 self-end mdmax:pt-0 mdmax:w-full h-full">
            <ImageItem :source="require('@/assets/images/loan/snpl.webp')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Installment',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  }
}
</script>
